import SelectedPaymentOptionView from '@akinon/next/components/selected-payment-option-view';
import { useLocalization } from '@akinon/next/hooks';
import { CheckoutPaymentOption } from '@akinon/next/types';
import PaymentOptionButtons from './payment-option-buttons';
import { StoreCredits } from './options/store-credits';
import ApplePay from './options/apple-pay';
import { useState } from 'react';
import { useSetPaymentOptionMutation } from '@akinon/next/data/client/checkout';
const currencies = ['aed', 'sar', 'qar', 'omr', 'bhd', 'kwd'];

const createPaymentOptions = (prefixes: string[], currencies: string[], view: any): Array<CheckoutPaymentOption> => {
  return prefixes.flatMap(prefix =>
    currencies.map(currency => ({
      slug: `${prefix}-${currency}`,
      view
    }))
  );
};

export const PaymentOptionViews: Array<CheckoutPaymentOption> = createPaymentOptions(
  ['apple-pay-wallet', 'test-apple-pay-wallet'],
  currencies,
  ApplePay
);


const PaymentStep = () => {
  const { t, locale } = useLocalization();
  const [loading, setLoading] = useState(false);
  const [loadingOptionPk, setLoadingOptionPk] = useState(null);
  const [setPaymentOption] = useSetPaymentOptionMutation();

  const handlePaymentOptionClick = async (optionPk) => {
    setLoading(true);
    setLoadingOptionPk(optionPk);

    try {
      await setPaymentOption(optionPk);
    } catch (error) {
      console.error('Payment option selection failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full rounded-[0.875rem] border border-gray-380 pt-1.5">
      <h3 className="w-full border-b border-gray-380 px-5 py-2.5 text-sm font-bold text-black-500">
        {t('checkout.payment.title')}
      </h3>
      <div className="w-full px-5 py-4">
        <>
          <StoreCredits />
          <PaymentOptionButtons
            onPaymentOptionClick={handlePaymentOptionClick}
            isLoading={loading}
            activeLoadingOptionPk={loadingOptionPk}
          >
            <SelectedPaymentOptionView />
          </PaymentOptionButtons>
        </>
      </div>
    </div>
  );
};

export default PaymentStep;
