import { forwardRef } from 'react';
import { SelectProps } from '@theme/components/types';
import clsx from 'clsx';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import { useLocalization } from '@akinon/next/hooks';
import { useWindowSize } from '@theme/hooks/use-window-size';

// TODO: Replace with react-select component to animate dropdown and chevron, maybe add a search feature etc.
const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { locale } = useLocalization();
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const {
    id,
    className,
    options,
    borderless = false,
    icon,
    iconSize,
    error,
    label,
    required = false,
    titleClassName,
    iconClassName,
    selectedValue,
    labelClassName,
    defaultValue = '',
    containerClassName,
    truncateText = false,
    ...rest
  } = props;

  const selectClass = twMerge(
    clsx(
      'relative h-[58px] w-full cursor-pointer appearance-none rounded-lg border-[0.5px] bg-white py-[18px] pe-9 ps-5 text-base leading-4 tracking-[-0.03em]',
      'focus-visible:outline-none', // disable outline on focus
      error
        ? 'border-error-650 text-error-650 focus:border-error-650'
        : 'border-gray-480 text-black hover:border-black focus:border-black'
    ),
    className
  );

  const Label = () => {
    if (!label) return null;
    return (
      <label
        htmlFor={id}
        className={clsx(
          'pointer-events-none absolute left-4 z-10 -translate-y-1/2 bg-white px-[3px] text-sm leading-5 text-gray-980',
          labelClassName
        )}
      >
        {label}
      </label>
    );
  };
  const Icons = () => {
    return (
      <Icon
        name="chevron-down"
        size={22}
        className={twMerge(
          clsx(
            'absolute-center-y pointer-events-none end-3.5 z-10 cursor-pointer',
            'focus-visible:outline-none',
            error ? 'text-error-650' : 'text-black'
          ),
          iconClassName
        )}
      />
    );
  };

  const formatTruncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <>
      <div className={twMerge('relative flex flex-col', containerClassName)}>
        <Label />
        <select
          {...rest}
          ref={ref}
          className={selectClass}
          value={selectedValue}
        >
          {options?.map((option, index) => (
            <option
              key={option?.value}
              value={option?.value}
              className={twMerge(
                clsx('mx-3 px-3 pb-3.5 pt-3 text-black'),
                option?.class
              )}
              disabled={option?.value === '' && index === 0}
            >
              {truncateText
                ? formatTruncateText(option?.label, isMobile ? 15 : 20)
                : option?.label}
            </option>
          ))}
        </select>
        <Icons />
      </div>
      {error && (
        <span className="mt-3 text-xs text-error-650">{error?.message}</span>
      )}
    </>
  );
});

Select.displayName = 'Select';

export { Select };
