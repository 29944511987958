'use client';

import { useLocalization } from '@akinon/next/hooks';
import { useState, useEffect } from 'react';

interface UseQuickEligibilityProps {
  scheduled: boolean;
  latitude: string | number;
  longitude: string | number;
}

interface QuickEligibilityResponse {
  name: string;
  erp_code: string;
  quick_eligable: boolean;
}

export const useQuickEligibility = ({
  scheduled,
  latitude,
  longitude
}: UseQuickEligibilityProps): boolean => {
  const [isQuickEligible, setIsQuickEligible] = useState(false);
  const { currency } = useLocalization();

  useEffect(() => {
    const fetchQuickEligibility = async () => {
      if (!latitude || !longitude) {
        console.error('Latitude and longitude are required');
        return;
      }

      try {
        const response = await fetch(
          `/api/ffc?scheduled=${scheduled}&latitude=${latitude}&longitude=${longitude}&currency=${currency}`,
        );
        const data: QuickEligibilityResponse | { error: any } =
          await response.json();

        if (response.ok && 'quick_eligable' in data) {
          setIsQuickEligible(data.quick_eligable);
        } else {
          console.error('Error fetching quick eligibility:', data);
          setIsQuickEligible(false);
        }
      } catch (error) {
        console.error('Error fetching quick eligibility:', error);
        setIsQuickEligible(false);
      }
    };

    fetchQuickEligibility();
  }, [scheduled, latitude, longitude]);

  return isQuickEligible;
};
