import { useAppSelector } from '@akinon/next/redux/hooks';
import { RootState } from '@theme/redux/store';
import { Radio } from '@theme/components';
import { usePaymentOptions } from '@akinon/next/hooks/use-payment-options';
import { Image } from '@akinon/next/components';
import { LoaderLulu } from '@theme/components/loader-lulu';
import clsx from 'clsx';

const isSafariOrIOSApplePayAvailable = () => {
  const isSafari = () => {
    return (
      typeof navigator !== 'undefined' &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    );
  };

  const isIOSDevice = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent);
  };

  const isApplePayAvailable = () => {
    return (
      typeof window !== 'undefined' &&
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments()
    );
  };

  return isSafari() || (isIOSDevice() && isApplePayAvailable());
};

const PaymentOptionButtons = ({
  onPaymentOptionClick,
  children,
  isLoading,
  activeLoadingOptionPk
}) => {
  const { preOrder } = useAppSelector((state: RootState) => state.checkout);
  const { filteredPaymentOptions } = usePaymentOptions();

  const getIcon = (payment_type, slug) => {
    if (slug.includes('apple-pay-wallet')) {
      return '/akn-apple-pay.png';
    }

    switch (payment_type) {
      case 'credit-card':
        return '/akn-credit-card.png';
      case 'funds_transfer':
        return '/akn-cash.png';
      case 'tabby':
        return '/akn-tabby.png';
      case 'tamara':
        return '/akn-tamara.png';
      case 'gift-card':
        return '/akn-lulu-gift.png';
      case 'wallet':
        return '/akn-lulu-wallet.png';
      default:
        return '/akn-credit-card.png';
    }
  };

  return (
    <div>
      {filteredPaymentOptions.map(
        (option) =>
          (!option.slug.includes('apple-pay-wallet') ||
            isSafariOrIOSApplePayAvailable()) && (
            <div
              key={`payment-option-${option?.pk}`}
              className={clsx(
                'flex flex-col gap-2.5 border-b border-gray-380 py-[0.875rem] last:border-none',
                option?.payment_type === 'loyalty_money' && 'hidden'
              )}
              data-testid={`checkout-payment-tab-${option?.pk}`}
            >
              <div
                className="flex items-center gap-2.5"
                onClick={() => onPaymentOptionClick(option.pk)}
              >
                <Radio
                  type="radio"
                  name="payment-option"
                  value={option.pk}
                  checked={preOrder?.payment_option?.pk === option?.pk}
                  className="h-5 w-5"
                />
                <div className="flex h-6 w-[2.1875rem] items-center justify-center rounded border border-gray-380 px-1.5 py-1">
                  <Image
                    src={getIcon(option?.payment_type, option?.slug)}
                    width={24}
                    height={24}
                    alt=""
                  />
                </div>
                <span className="cursor-pointer text-sm font-semibold text-black">
                  {option?.name}
                </span>
              </div>

              <div
                className={clsx(
                  'flex items-center justify-center transition-all',
                  activeLoadingOptionPk === option.pk
                    ? option.payment_type === 'credit_card'
                      ? 'min-h-72'
                      : 'min-h-20'
                    : ''
                )}
              >
                <div
                  className={clsx(
                    'transition-all duration-300 ease-in-out',
                    activeLoadingOptionPk === option.pk
                      ? 'opacity-100'
                      : 'opacity-0'
                  )}
                >
                  {activeLoadingOptionPk === option.pk && isLoading && (
                    <LoaderLulu />
                  )}
                </div>

                {!isLoading &&
                  preOrder?.payment_option?.pk === option?.pk &&
                  children}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default PaymentOptionButtons;
