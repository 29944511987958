import { countryShortCodeMap } from '@theme/countries';
import settings from '@theme/settings';

export const getCountryCode = (locale: string): string => {
  const code =
    countryShortCodeMap[
      locale.split('-')[1] ?? settings.localization.defaultLocaleValue
    ];

  return code;
};

export const checkThemeConflict = (
  theme: string,
  shipmentType: string
): boolean => {
  return theme === 'express' && shipmentType === 'Scheduled';
};
