import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization } from '@akinon/next/hooks';
import { useState, useEffect } from 'react';

export const useGetClientWidgetDataHandler = ({ slug }) => {
  const { locale } = useLocalization();
  const countryCode = locale.split('-')[1];

  const [state, setState] = useState({
    data: null,
    isLoading: true,
    isError: false,
    isSuccess: false
  });

  const localizedSlug = slug ? `${slug}-${countryCode}` : null;

  const {
    data: localizedData,
    isLoading: isLoadingLocalized,
    isError: isErrorLocalized,
    isSuccess: isSuccessLocalized
  } = useGetWidgetQuery(localizedSlug, {
    skip: !slug
  });

  const {
    data: defaultData,
    isLoading: isLoadingDefault,
    isError: isErrorDefault,
    isSuccess: isSuccessDefault
  } = useGetWidgetQuery(slug, {
    skip: !!localizedData || !slug || isLoadingLocalized
  });

  useEffect(() => {
    const finalData = localizedData || defaultData || null;
    const isLoading = isLoadingLocalized || isLoadingDefault;
    const isError = isErrorLocalized || isErrorDefault;
    const isSuccess = isSuccessLocalized || isSuccessDefault;

    setState({
      data: finalData,
      isLoading,
      isError,
      isSuccess
    });
  }, [
    localizedData,
    defaultData,
    isLoadingLocalized,
    isLoadingDefault,
    isErrorLocalized,
    isErrorDefault,
    isSuccessLocalized,
    isSuccessDefault
  ]);

  return state;
};
