import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type ShimmerProps = {
  className?: string;
};

export const Shimmer: React.FC<ShimmerProps> = ({ className }) => {
  return (
    <div
      className={clsx(['absolute left-0 top-0 h-full w-full animate-pulse'])}
    >
      <div
        className={twMerge(
          'shadow-[0 0 30px rgba(255,255,255,0.2)] h-full w-full bg-black bg-opacity-20',
          className
        )}
      ></div>
    </div>
  );
};
