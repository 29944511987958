'use client';

import { Middleware } from '@reduxjs/toolkit';
import {
  setPreOrder,
  setShippingStepCompleted
} from '@akinon/next/redux/reducers/checkout';
import { RootState, TypedDispatch } from 'redux/store';
import { checkoutApi } from '@akinon/next/data/client/checkout';
import { CheckoutContext, PreOrder } from '@akinon/next/types';
import { isScheduledDelivery } from '@theme/utils';
import { getCookie } from '@akinon/next/utils';

interface CheckoutResult {
  payload: {
    errors?: Record<string, string[]>;
    pre_order?: PreOrder;
    context_list?: CheckoutContext[];
  };
}

interface MiddlewareParams {
  getState: () => RootState;
  dispatch: TypedDispatch;
}

export const preOrderMiddleware: Middleware = ({
  getState,
  dispatch
}: MiddlewareParams) => {
  return (next) => (action) => {
    const result: CheckoutResult = next(action);
    const preOrder = result?.payload?.pre_order;

    if (
      !preOrder ||
      action?.meta?.arg?.endpointName === 'guestLogin' ||
      action?.meta?.arg?.endpointName === 'getCheckoutLoyaltyBalance'
    ) {
      return result;
    }

    const {
      deliveryOptions,
      addressList: addresses,
      shippingOptions,
      dataSourceShippingOptions,
      paymentOptions,
      installmentOptions
    } = getState().checkout;
    const { endpoints: apiEndpoints } = checkoutApi;

    if (preOrder.is_redirected) {
      const contextList = result?.payload?.context_list;

      if (
        contextList.find(
          (ctx) => ctx.page_name === 'RedirectionPaymentSelectedPage'
        )
      ) {
        dispatch(
          apiEndpoints.setPaymentOption.initiate(preOrder.payment_option?.pk)
        );
        return;
      }
    }

    dispatch(setPreOrder(preOrder));

    if (!preOrder.delivery_option && deliveryOptions.length > 0) {
      dispatch(
        apiEndpoints.setDeliveryOption.initiate(
          deliveryOptions.find((opt) => opt.delivery_option_type === 'customer')
            ?.pk
        )
      );
    }

    if (
      !preOrder.bags &&
      isScheduledDelivery(preOrder, getCookie('pz-locale') || 'en-ae')
    ) {
      dispatch(
        apiEndpoints.setDeliveryBags.initiate({
          sku: '1977302',
          quantity: 1
        })
      );
    }

    if (
      shippingOptions.length > 0 &&
      (!preOrder.shipping_option ||
        !shippingOptions.find((opt) => opt.pk === preOrder.shipping_option?.pk))
    ) {
      dispatch(apiEndpoints.setShippingOption.initiate(shippingOptions[0].pk));
    }

    if (
      dataSourceShippingOptions.length > 0 &&
      !preOrder.data_source_shipping_options
    ) {
      const selectedDataSourceShippingOptionsPks =
        dataSourceShippingOptions.map(
          (opt) => opt.data_source_shipping_options[0].pk
        );

      dispatch(
        apiEndpoints.setDataSourceShippingOptions.initiate(
          selectedDataSourceShippingOptionsPks
        )
      );
    }

    if (!preOrder.payment_option && paymentOptions.length > 0) {
      dispatch(apiEndpoints.setPaymentOption.initiate(paymentOptions[0].pk));
    }

    if (
      !preOrder.installment &&
      preOrder.payment_option?.payment_type !== 'saved_card' &&
      installmentOptions.length > 0
    ) {
      dispatch(
        apiEndpoints.setInstallmentOption.initiate(installmentOptions[0].pk)
      );
    }

    dispatch(
      setShippingStepCompleted(
        [
          preOrder.delivery_option?.delivery_option_type === 'retail_store'
            ? true
            : preOrder.shipping_address?.pk,
          preOrder.billing_address?.pk,
          preOrder.shipping_option?.pk,
          addresses.length > 0
        ].every(Boolean)
      )
    );

    return result;
  };
};

export default preOrderMiddleware;
