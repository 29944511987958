'use client';

import { Middleware } from '@reduxjs/toolkit';
import { checkoutApi } from '@akinon/next/data/client/checkout';
import { RootState, TypedDispatch } from 'redux/store';
import { getCookie, removeCookie } from '@akinon/next/utils';
import { isScheduledDelivery } from '@theme/utils';

const getThemeBasedShippingOption = (
  scheduledOptions: any[],
  theme: string | undefined
) => {
  let selectedOptionPk;

  if (theme === 'scheduled') {
    selectedOptionPk = scheduledOptions.find(
      (option) => option.shipping_option_name === 'Scheduled Delivery'
    )?.pk;
  } else if (theme === 'express') {
    selectedOptionPk = scheduledOptions.find(
      (option) => option.shipping_option_name === 'Express'
    )?.pk;
  }

  return selectedOptionPk || scheduledOptions[0]?.pk;
};

const checkoutMiddleware: Middleware<{}, RootState> = ({
  getState,
  dispatch
}: {
  getState: () => RootState;
  dispatch: TypedDispatch;
}) => {
  return (next) => (action) => {
    const theme = getCookie('theme');

    if (
      action?.meta?.arg?.endpointName === 'setAttributeBasedShippingOptions'
    ) {
      const { attributeBasedShippingOptions } = getState().checkout;
      const modifiedOptions: Record<string, number> = {};

      Object.entries(attributeBasedShippingOptions).forEach(
        ([key, options]) => {
          if (key === 'Scheduled delivery') {
            const scheduledOptions = options.attribute_based_shipping_options;
            modifiedOptions[key] = getThemeBasedShippingOption(
              scheduledOptions,
              theme
            );
          } else {
            modifiedOptions[key] =
              options.attribute_based_shipping_options[0].pk;
          }
        }
      );

      return next({
        ...action,
        meta: {
          ...action.meta,
          arg: {
            ...action.meta.arg,
            originalArgs: modifiedOptions
          }
        }
      });
    }

    if (action?.meta?.arg?.endpointName === 'setAddresses') {
      const { addressList: addresses } = getState().checkout;
      const selectedAddressPk = getCookie('selectedAddress');
      const addressPk = selectedAddressPk
        ? JSON.parse(selectedAddressPk)
        : addresses[0]?.pk;

      if (addressPk) {
        return next({
          ...action,
          meta: {
            ...action.meta,
            arg: {
              ...action.meta.arg,
              originalArgs: {
                shippingAddressPk: addressPk,
                billingAddressPk: addressPk
              }
            }
          }
        });
      }
    }

    const result = next(action);
    const { endpoints: apiEndpoints } = checkoutApi;

    if (action.type === 'checkout/setPreOrder') {
      const {
        addressList: addresses,
        attributeBasedShippingOptions,
        preOrder
      } = getState().checkout;
      const selectedAddressPk = getCookie('selectedAddress');
      const addressPk = selectedAddressPk
        ? JSON.parse(selectedAddressPk)
        : addresses[0]?.pk;
      const locale = getCookie('pz-locale') || 'en-ae';
      const isAddressChange = getCookie('isSelectedAddressChange') === 'true';
      const isScheduled = isScheduledDelivery(preOrder, locale);

      if (
        ((!preOrder.shipping_address || !preOrder.billing_address) &&
          addresses.length > 0 &&
          (!preOrder.delivery_option ||
            preOrder.delivery_option.delivery_option_type === 'customer') &&
          (preOrder.bags || (!preOrder.bags && !isScheduled))) ||
        (preOrder.bags && isAddressChange) ||
        (!isScheduled && isAddressChange)
      ) {
        removeCookie('isSelectedAddressChange');
        dispatch(
          apiEndpoints.setAddresses.initiate({
            shippingAddressPk: addressPk,
            billingAddressPk: addressPk
          })
        );
      }

      if (
        Object.keys(attributeBasedShippingOptions).length > 0 &&
        !preOrder.attribute_based_shipping_options
      ) {
        const initialSelectedOptions: Record<string, number> = {};

        Object.entries(attributeBasedShippingOptions).forEach(
          ([key, options]) => {
            if (key === 'Scheduled delivery') {
              const scheduledOptions = options.attribute_based_shipping_options;
              initialSelectedOptions[key] = getThemeBasedShippingOption(
                scheduledOptions,
                theme
              );
            } else {
              initialSelectedOptions[key] =
                options.attribute_based_shipping_options[0].pk;
            }
          }
        );

        dispatch(
          apiEndpoints.setAttributeBasedShippingOptions.initiate(
            initialSelectedOptions
          )
        );
      }
    }

    return result;
  };
};

export default checkoutMiddleware;
