'use client';

import ReactPortal from './react-portal';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef } from 'react';
import { useOnClickOutside } from '@akinon/next/hooks';

export interface ModalProps {
  portalId: string;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: React.ReactNode;
  showCloseButton?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  disabledOutsideClick?: boolean;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    portalId,
    open,
    setOpen,
    title = '',
    showCloseButton = true,
    className,
    headerClassName,
    titleClassName,
    disabledOutsideClick = false
  } = props;
  const modalRef = useRef(null);

  if (!disabledOutsideClick) useOnClickOutside(modalRef, () => setOpen(false));

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  if (!open) return null;

  return (
    <ReactPortal wrapperId={portalId}>
      <div className="fixed left-0 top-0 z-50 h-screen w-screen bg-black bg-opacity-20" />
      <section
        ref={modalRef}
        className={twMerge(
          'fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 bg-white',
          className
        )}
      >
        {(showCloseButton || title) && (
          <div
            className={twMerge(
              'flex border-b border-gray-400 px-6 py-4',
              headerClassName
            )}
            style={{
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0'
            }}
          >
            {title && (
              <h3 className={twMerge('text-lg font-light', titleClassName)}>
                {title}
              </h3>
            )}
            {showCloseButton && (
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="ms-auto"
              >
                <Icon name="close" size={16} />
              </button>
            )}
          </div>
        )}
        {children}
      </section>
    </ReactPortal>
  );
};
