'use client';

import { Button } from '@akinon/next/components';
import { useLocalization } from '@akinon/next/hooks';
interface LanguageSelectProps {
  className?: string;
}

export const LanguageSelect = (props: LanguageSelectProps) => {
  const { t, locale, locales, setLocale } = useLocalization();
  const [languageCode, countryCode] = locale.split('-');

  const handleChange = async () => {
    if (languageCode === locales[0].localePath) {
      setLocale(`${locales[1].localePath}-${countryCode}`);
    } else {
      setLocale(`${locales[0].localePath}-${countryCode}`);
    }
  };

  return (
    <>
      <Button
        onClick={handleChange}
        className="flex items-center justify-center border-none bg-transparent px-0 text-lg font-medium text-black hover:border-none hover:bg-transparent hover:text-black"
      >
        {languageCode !== locales[1].localePath
          ? t('common.navbar.text')
          : locales[0].label}
      </Button>
    </>
  );
};
