import React from 'react';
import clsx from 'clsx';

type ProgressBarProps = {
    progress: number; 
    filledClass?: string; 
    emptyClass?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> =({progress,filledClass="bg-[#20590C]",emptyClass="bg-[#E6EAEEB2]"})=> {

    return (
        <div className={clsx("w-full h-full rounded-full", emptyClass)}>
        <div
          className={clsx("h-full rounded-full", filledClass)}
          style={{ width: `${progress}%` }}></div>
      </div>
      );
}