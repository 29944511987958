import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface StepProgressProps {
  steps: number;
  completedSteps: number;
  message: string;
  textClass?: string;
  dotClass?: string;
  lineClass?: string;
}

export const StepProgress: React.FC<StepProgressProps> = ({
  steps,
  completedSteps,
  message,
  textClass,
  dotClass,
  lineClass
}) => {
  const stepsArray = Array.from({ length: steps }, (_, i) => i + 1);

  return (
    <div className="flex flex-col gap-3">
      <div
        className={twMerge(
          'text-sm font-normal leading-4 tracking-[-0.03em]',
          textClass
        )}
      >
        {message}
      </div>
      <div className="flex items-center">
        {stepsArray.map((_, index) => (
          <div key={index} className="flex items-center">
            <div
              className={twMerge(
                clsx('h-2.5 w-2.5 rounded-xl bg-gray-380', {
                  [dotClass]: index < completedSteps
                })
              )}
            ></div>
            {index < stepsArray.length - 1 && (
              <div
                className={twMerge(
                  clsx('h-0.5 w-[33px] bg-gray-380', {
                    [lineClass]: index < completedSteps - 1
                  })
                )}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
