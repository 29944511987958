import { IconProps } from '@theme/components/types';
import clsx from 'clsx';

export const Icon = (props: IconProps) => {
  const { name, size, className, style, ...rest } = props;

  const combinedStyle = {
    ...style,
    fontSize: size ? `${size}px` : undefined,
  };
  return (
    <i
      className={clsx(`flex pz-icon-${name}`, className)}
      {...rest}
      style={combinedStyle}
    />
  );
};
