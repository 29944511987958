import { useState, useEffect, useCallback, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { getCookie, setCookie } from '@akinon/next/utils';
import { useLocalization } from '@akinon/next/hooks';
import { countries } from '@theme/countries';
import { useGetAddressesQuery } from '@akinon/next/data/client/address';
import { useSession } from 'next-auth/react';
import {
  updateErpCodeForLocation,
  getDefaultErpCode
} from '../actions/location-actions';

interface LocationState {
  country: string;
  locationTitle: string;
  latitude: number;
  longitude: number;
  ffc: string;
  address_primary?: string;
}

interface AddressExtraField {
  address_primary: string;
  latitude: number;
  longitude: number;
  ffc: string;
}

interface Address {
  country: { code: string };
  township?: { name: string };
  name: string;
  pk: number;
  extra_field: AddressExtraField;
}

const LIBRARIES: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places'];
const GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_MAP_API_KEY ||
  'AIzaSyARU3JY2ZLLxrjKd5sa9FK0Gk368lvxI9g';

const defaultLocations = [
  {
    country: 'UAE',
    locationTitle: 'Al Nahyan',
    latitude: 24.4351413,
    longitude: 54.4120335,
    ffc: '2480'
  },
  {
    country: 'BAHRAIN',
    locationTitle: 'Al Dair',
    latitude: 26.238577,
    longitude: 50.651374,
    ffc: '3650'
  },
  {
    country: 'QATAR',
    locationTitle: 'Al Gharrafa',
    latitude: 25.3380296,
    longitude: 51.4601424,
    ffc: '3040'
  },
  {
    country: 'OMAN',
    locationTitle: 'Bousher',
    latitude: 23.5913019,
    longitude: 58.4106424,
    ffc: '3440'
  },
  {
    country: 'KUWAIT',
    locationTitle: 'Dajeej',
    latitude: 29.2645757,
    longitude: 47.9636569,
    ffc: '3250'
  },
  {
    country: 'KSA',
    locationTitle: 'Al Khaleej',
    latitude: 24.7522805,
    longitude: 46.7693938,
    ffc: '3765'
  }
];

const defaultLocation: LocationState = {
  country: '',
  locationTitle: '',
  latitude: 0,
  longitude: 0,
  ffc: '0'
};

const useUserLocation = () => {
  const { locale, currency } = useLocalization();
  const { data: session } = useSession();
  const { data: addressData, isSuccess, isLoading } = useGetAddressesQuery();
  const [location, setLocationState] = useState<LocationState>(defaultLocation);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES
  });

  const countryCode = locale.split('-')[1];
  const activeCountry =
    countries.find((country) => country.value === countryCode) || null;
  const currentDefaultLocation =
    defaultLocations.find(
      (location) =>
        location.country.toUpperCase() === activeCountry?.en.label.toUpperCase()
    ) || defaultLocations[0];

  const createLocationFromAddress = (address: Address): LocationState => ({
    latitude: address.extra_field.latitude,
    longitude: address.extra_field.longitude,
    locationTitle: address.township?.name || address.name,
    ffc: address.extra_field.ffc,
    country: address.country.code
  });

  const findAddress = (addresses: Address[], isPrimary = false) => {
    const upperCountryCode = activeCountry?.value.toUpperCase();
    return addresses.find((address) => {
      if (!upperCountryCode) return false;
      const isMatchingCountry = address.country.code === upperCountryCode;
      if (!isMatchingCountry) return false;
      return isPrimary
        ? address.extra_field.address_primary === 'true'
        : Number(address.pk) === Number(getCookie('selectedAddress'));
    });
  };

  const updateLocation = useCallback(
    async (newLocation) => {
      const { latitude, longitude, townshipName } = newLocation.extra_field || newLocation;
      const bc = new BroadcastChannel('preorder_channel');
      bc.postMessage('update_preorder');

      try {
        const queryParams = new URLSearchParams({
          latitude: String(latitude),
          longitude: String(longitude),
          currency
        });

        const response = await fetch(`/api/ffc?${queryParams}`, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();

        const selectedAddressPk = getCookie('selectedAddress');

        const selectedAddress = addressData?.results?.find((address: Address) => address.pk === Number(selectedAddressPk));

        const updatedLocation = {
          ...location,
          ...newLocation,
          locationTitle: selectedAddress?.township?.name || townshipName || data?.name,
          ffc: data.erp_code,
          country: data.country
        };

        setLocationState(updatedLocation);

        setCookie('userLocation', JSON.stringify(updatedLocation));

        await updateErpCodeForLocation({
          country: updatedLocation.country,
          ffc: updatedLocation.ffc
        });

        return updatedLocation;
      } catch (error) {
        console.error('Error updating location:', error);
        return location;
      }
    },
    [location, currency]
  );

  const getLocation = useCallback(() => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        updateLocation({
          latitude: coords.latitude,
          longitude: coords.longitude,
          locationTitle: location?.locationTitle || '',
          country: location?.country || '',
          ffc: location?.ffc || '',
          address_primary: location.address_primary || 'false'
        });
      },
      (error) => console.error('Geolocation error:', error.message)
    );
  }, [location, updateLocation]);

  const setDefaultLocation = useCallback(async () => {
    let newLocation: LocationState;

    if (session && isSuccess && !isLoading && addressData?.results) {
      const selectedAddress = findAddress(addressData.results);
      const primaryAddress = findAddress(addressData.results, true);

      if (selectedAddress || primaryAddress) {
        newLocation = createLocationFromAddress(
          selectedAddress || primaryAddress
        );
      } else {
        newLocation = currentDefaultLocation;
      }
    } else {
      try {
        const userLocationCookie = getCookie('userLocation');
        newLocation = userLocationCookie
          ? JSON.parse(userLocationCookie)
          : currentDefaultLocation;
      } catch {
        newLocation = currentDefaultLocation;
      }
    }

    setLocationState(newLocation);
    setCookie('userLocation', JSON.stringify(newLocation));

    return newLocation;
  }, [
    session,
    isSuccess,
    isLoading,
    addressData?.results,
    currentDefaultLocation,
    activeCountry
  ]);

  useEffect(() => {
    if (isLoaded && !loadError && isSuccess && !isLoading) {
      setDefaultLocation();
    }
  }, [isLoaded, loadError, isSuccess, isLoading, setDefaultLocation]);

  return {
    location,
    getLocation,
    setLocation: updateLocation
  };
};

export default useUserLocation;
