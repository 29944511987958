'use client';

import { useCallback, useEffect, useState } from 'react';
import {
  useGetAddressesQuery,
  useEditAddressMutation
} from '@akinon/next/data/client/address';
import { City, Country, Township, Address } from '@akinon/next/types';
import { useLocalization } from '@akinon/next/hooks';
import useUserLocation from '@theme/hooks/use-user-location';
import { useSession } from 'next-auth/react';
import { getCookie, removeCookie, setCookie } from '@akinon/next/utils';
import { countries } from '@theme/countries';

interface ExtraField {
  latitude: string | number;
  longitude: string | number;
  address_primary?: string;
}

export const useAddress = () => {
  const { data: session, status } = useSession();
  const { locale } = useLocalization();
  const { data: addressData, refetch } = useGetAddressesQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const [editAddress] = useEditAddressMutation();
  const language = locale.split('-')[1];
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { setLocation } = useUserLocation();
  let localeChangedFetch = true;

  const addressList = addressData?.results?.filter((address: Address) =>
    language
      ? address.country.code === language.toUpperCase()
      : address.country.code === 'QA'
  );

  const primaryAddress = addressList?.find((address: Address) => {
    return (
      (address?.extra_field as { address_primary: string })?.address_primary ===
      'true'
    );
  });

  const unsetPrimaryAddress = async () => {
    const primaryAddresses = addressList?.filter(
      (address: Address) =>
        (address?.extra_field as { address_primary: string })
          ?.address_primary === 'true'
    );

    for (const address of primaryAddresses) {
      const updatedAddressData = {
        ...address,
        city: address?.city?.pk as unknown as City,
        country: address?.country?.pk as unknown as Country,
        township: address?.township?.pk as unknown as Township,
        primary: false,
        address_primary: 'false',
        ffc_last_updated_datetime: new Date().toISOString()
      };

      const response = await editAddress(updatedAddressData);
      const changedAddress = 'data' in response ? response.data : null;

      const storedAddressPk = getCookie('selectedAddress');

      if (changedAddress && Number(storedAddressPk) === address.pk) {
        setSelectedAddress(changedAddress.pk);
        setCookie('selectedAddress', changedAddress.pk.toString());
      }
    }
  };

  const setDefaultAddress = async (address: Address) => {
    const updatedAddressData = {
      ...address,
      city: address?.city?.pk as unknown as City,
      country: address?.country?.pk as unknown as Country,
      township: address?.township?.pk as unknown as Township,
      address_primary: 'true',
      ffc_last_updated_datetime: new Date().toISOString()
    };

    const storedAddressPk = getCookie('selectedAddress');

    await unsetPrimaryAddress();
    const response = await editAddress(updatedAddressData);
    const data = 'data' in response ? response.data : null;

    if (data && Number(storedAddressPk) === address.pk) {
      setCookie('selectedAddress', data.pk.toString());
      setSelectedAddress(data);

      await setLocation(data);
    }
  };

  const findSelectedAddress = async () => {
    const storedAddressPk = getCookie('selectedAddress');

    if (storedAddressPk && addressList?.length > 0) {
      const parsedAddressPk = JSON.parse(storedAddressPk);
      const foundAddress = addressList?.find(
        (address) => address.pk === parsedAddressPk
      );
      if (foundAddress && foundAddress.pk !== selectedAddress?.pk) {
        setSelectedAddress(foundAddress);
      } else if (primaryAddress) {
        setSelectedAddress(primaryAddress);
      } else {
        setSelectedAddress(addressList[0]);
      }
    } else if (!storedAddressPk && addressList?.length > 0) {
      if (primaryAddress) {
        setCookie('selectedAddress', JSON.stringify(primaryAddress.pk));
        setSelectedAddress(primaryAddress);
        await setLocation(primaryAddress.extra_field);
      } else {
        setDefaultAddress(addressList[0]);
        await setLocation(addressList[0]);
        setCookie('selectedAddress', JSON.stringify(addressList[0].pk));

        setSelectedAddress(addressList[0]);
      }
    }
  };
  const getSelectedLocationByCountry = useCallback((countryCode) => {
    return countries.find((country) => country.value === countryCode) || null;
  }, []);

  const activeCountry = getSelectedLocationByCountry(locale.split('-')[1]);

  const updateAddressAfterLocaleChange = useCallback(async () => {
    if (
      localStorage.getItem('localeChanged') === 'true' &&
      localeChangedFetch
    ) {
      localeChangedFetch = false;
      await refetch();
      const newAddressList = addressData?.results?.filter((address: Address) =>
        language
          ? address.country.code === language.toUpperCase()
          : address.country.code === 'QA'
      );

      if (newAddressList && newAddressList.length > 0) {
        const primaryAddress = addressData.results.find(
          (address) =>
            address.country.code === activeCountry?.value.toUpperCase() &&
            (address?.extra_field as { address_primary: string })
              ?.address_primary === 'true'
        );

        setSelectedAddress(primaryAddress);
        setCookie('selectedAddress', JSON.stringify(primaryAddress.pk));

        await setLocation(primaryAddress);
      } else {
        setSelectedAddress(null);
        removeCookie('selectedAddress');
      }

      localStorage.removeItem('localeChanged');
    }
  }, [language, refetch, setLocation, addressData]);

  useEffect(() => {
    updateAddressAfterLocaleChange();
  }, [updateAddressAfterLocaleChange]);

  useEffect(() => {
    if (session && addressData) {
      findSelectedAddress();
    }
  }, [session, addressData]);

  useEffect(() => {
    if (session && addressData && addressList?.length > 0) {
      const selectedAddressPk = getCookie('selectedAddress');

      if (selectedAddressPk) {
        const parsedPk = Number(JSON.parse(selectedAddressPk));

        const currentSelectionExists = addressList.some(
          (address) => address.pk === parsedPk
        );

        if (!currentSelectionExists && !selectedAddress) {
          const addressToSet = primaryAddress || addressList[0];
          setCookie('selectedAddress', JSON.stringify(addressToSet.pk));
          setSelectedAddress(addressToSet);

          const extraField = addressToSet?.extra_field as ExtraField;
          if (extraField) {
            const data = {
              latitude: extraField.latitude,
              longitude: extraField.longitude
            };
            setLocation(data);
          }
        }
      } else {
        const addressToSet = primaryAddress || addressList[0];
        setCookie('selectedAddress', JSON.stringify(addressToSet.pk));
        setSelectedAddress(addressToSet);

        const extraField = addressToSet?.extra_field as ExtraField;
        if (extraField) {
          const data = {
            latitude: extraField.latitude,
            longitude: extraField.longitude
          };
          setLocation(data);
        }
      }
    }
  }, [
    session,
    addressData,
    addressList,
    primaryAddress,
    setLocation,
    selectedAddress
  ]);

  useEffect(() => {
    if (status === 'authenticated') {
      refetch();
    }
  }, [status, refetch]);

  return {
    setDefaultAddress,
    unsetPrimaryAddress,
    selectedAddress,
    setSelectedAddress,
    addressList,
    updateAddressAfterLocaleChange,
    primaryAddress
  };
};
