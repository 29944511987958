import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';
import localeTr from 'air-datepicker/locale/tr';
import 'air-datepicker/air-datepicker.css';
import React, { useEffect, useRef } from 'react';

type DatepickerProps = AirDatepickerOptions & {
  inputClass?: string;
  placeholder?: string;
  selectedDate?: Date | null;
};

export const Datepicker: React.FC<DatepickerProps> = ( props ) => {
  const { inputClass, placeholder = 'Select Date', selectedDate, ...datepickerProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const datepickerRef = useRef<AirDatepicker | null>(null);

  useEffect(() => {
    datepickerRef.current?.destroy();

    if (inputRef.current) {
      datepickerRef.current = new AirDatepicker(inputRef.current, {
        locale: localeTr,
        classes: 'datepicker-own-class',
        maxDate: new Date(),
        buttons:'clear',
        ...datepickerProps
      });
    }
  }, []);

  useEffect(() => {
    if (selectedDate && datepickerRef.current) {
      datepickerRef.current.selectDate(selectedDate);
    }
  }, []);

  useEffect(() => {
    datepickerRef.current?.update({ ...datepickerProps });
  }, [datepickerProps]);

  return <input ref={inputRef} className={inputClass} placeholder={placeholder} />;
};
