import React, { useState } from 'react';
import clsx from 'clsx';
import { Icon } from '@theme/components';

type StarRatingSelectorProps = {
  initialFilledStar: number;
  totalStars: number;
  size?: number;
  onChange?: (filledStars: number) => void;
  reverse?: boolean; 
  filledClass?: string;
  emptyClass?: string;
};

export const StarRatingSelector: React.FC<StarRatingSelectorProps> = ({
  initialFilledStar,
  totalStars,
  size,
  onChange,
  reverse = false,
  filledClass,
  emptyClass       
}) => {
  const [filledStar, setFilledStar] = useState(initialFilledStar);

  const handleClick = (index: number) => {
    const newFilledStar = index + 1;
    setFilledStar(newFilledStar);
    if (onChange) {
      onChange(newFilledStar);
    }
  };

  const stars = Array.from({ length: totalStars }).map((_, index) => (
    <Icon
      key={index}
      name={index < filledStar ? 'review-star-filled' : 'review-star'}
      size={size || 10}
      className={clsx('cursor-pointer',  index < filledStar ? (filledClass ? filledClass : 'text-[#E59819]') : emptyClass ? emptyClass : ''
      )}
      onClick={() => handleClick(index)}
    />
  ));

  return (
    <>
      {reverse ? stars.reverse() : stars}
    </>
  );
};