import { forwardRef, useState, useEffect } from 'react';
import { CheckboxProps } from './types';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@akinon/next/hooks';
import { Icon } from './icon';
import clsx from 'clsx';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    children,
    error,
    checked,
    inputClassName,
    inputWidth = 16,
    inputHeight = 16,
    labelClassName,
    errorClassName,
    iconClassName,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);
  const [visible, setVisible] = useState(checked || false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  useEffect(() => {
    setVisible(checked ?? false);
  }, [checked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisible(e.target.checked);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <label
      className={twMerge(
        clsx(
          'flex cursor-pointer flex-col text-xs',
          props.disabled && 'pointer-events-none opacity-50'
        ),
        props.className
      )}
    >
      <div className="flex items-start">
        <span className="flex-center relative" >
          <input
            style={style}
            type="checkbox"
            {...rest}
            ref={ref}
            className={twMerge(
              'appearance-none rounded border border-black border-opacity-40 checked:border-primary checked:bg-primary hover:border-primary focus:outline-none',
              inputClassName
            )}
            onChange={handleChange}
            checked={visible}
          />
          {visible && (
            <Icon
              name="check"
              className={twMerge(
                'absolute text-[8px] text-primary-foreground transition-all',
                iconClassName
              )}
            />
          )}
        </span>
        {children && (
          <span className={twMerge('ms-2.5', labelClassName)}>{children}</span>
        )}
      </div>
      {error && (
        <span className={twMerge('mt-1 text-sm text-error', errorClassName)}>
          {error.message}
        </span>
      )}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
