import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Props as TabProps } from './tab';
import { Tab } from './tab';

type Props = {
  children: ReactElement<TabProps>[];
  preSelectedTabIndex?: number;
  tabBarPosition?: string;
  className?: string;
  titlesContainerClassName?: string;
};
export const Tabs = (props: Props) => {
  const {
    children,
    preSelectedTabIndex,
    tabBarPosition,
    className,
    titlesContainerClassName,
    ...rest
  } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    preSelectedTabIndex || 0
  );

  // Yalnızca benzersiz title değerlerine sahip Tab öğelerini içeren bir dizi oluştur
  const uniqueChildren = children?.reduce((unique, child) => {
    if (!unique.some((item) => item?.props?.title === child?.props?.title)) {
      unique.push(child);
    }
    return unique;
  }, []);

  return (
    <div
      className={twMerge(
        clsx({
          'flex flex-col-reverse': tabBarPosition === 'bottom',
          'flex flex-row gap-4':
            tabBarPosition === 'left' || tabBarPosition === 'right',
          'flex-row-reverse': tabBarPosition === 'right'
        }),
        className
      )}
      {...rest}
    >
      <ul
        key={selectedTabIndex}
        className={clsx(
          'mb-8 flex lg:mb-9',
          {
            'max-w-[10rem] flex-col':
              tabBarPosition === 'left' || tabBarPosition === 'right'
          },
          titlesContainerClassName
        )}
      >
        {uniqueChildren?.map((item, index) => (
          <Tab
            key={item?.props?.title}
            title={item?.props?.title}
            index={index}
            icon={item?.props?.icon}
            iconPosition={item?.props?.iconPosition}
            isActive={index === selectedTabIndex}
            setSelectedTab={setSelectedTabIndex}
            className={item?.props?.className}
            activeClassName={item?.props?.activeClassName}
          />
        ))}
      </ul>
      {uniqueChildren && (
        <div className="w-full">{uniqueChildren[selectedTabIndex]}</div>
      )}
    </div>
  );
};
