'use client';

import { twMerge } from 'tailwind-merge';
import { useLocalization } from '@akinon/next/hooks';

interface DiscountPercentageProps {
  retailPrice: number;
  price: number;
  percentageSign?: string;
  percentageLabel?: string;
  className?: string;
}

export const DiscountPercentage: React.FC<DiscountPercentageProps> = ({
  retailPrice,
  price,
  percentageSign = '%',
  percentageLabel,
  className
}) => {
  const { t } = useLocalization();

  if (retailPrice <= 0) {
    return null;
  }

  if (!percentageLabel) {
    percentageLabel = t('product.discount_label');
  }
  const percentage = Math.round(
    ((retailPrice - price) / retailPrice) * 100
  );

  return (
    <span className={twMerge('rounded bg-primary/10 p-1 text-xs', className)}>
      {percentage}
      {percentageSign} {percentageLabel}
    </span>
  );
};

export default DiscountPercentage;
