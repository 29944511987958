'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import {
  useAddProductMutation,
  AddProductRequest
} from '@akinon/next/data/client/product';
import { basketApi, useLazyGetBasketDetailQuery } from '@akinon/next/data/client/basket';
import {
  openMiniBasket,
  setHighlightedItem
} from '@akinon/next/redux/reducers/root';
import { getCookie } from '@akinon/next/utils';

export const useAddProductToBasket = () => {
  const dispatch = useAppDispatch();
  const [addProduct, options] = useAddProductMutation();
  const [getBasketDetail] = useLazyGetBasketDetailQuery();

  const addProductToBasket = async ({
    product,
    quantity,
    attributes,
    shouldOpenMiniBasket = true
  }: AddProductRequest & { shouldOpenMiniBasket?: boolean }) => {
    const bc = new BroadcastChannel('preorder_channel');
    bc.postMessage('update_preorder');

    const requestParams: any = {
      product: product,
      quantity : quantity,
      attributes,
      namespace: getCookie('theme') || 'scheduled'
    };

    await addProduct(requestParams)
      .unwrap()
      .then((data) => {
        dispatch(
          basketApi.util.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data.basket);
            }
          )
        )
        getBasketDetail({namespace: getCookie('theme') || 'scheduled'})
      }

      )
      .then(() => {
        if (shouldOpenMiniBasket) {
          dispatch(openMiniBasket());
          dispatch(setHighlightedItem(product));
          setTimeout(() => {
            dispatch(setHighlightedItem(null));
          }, 3000);
        }
      });
  };

  return [addProductToBasket, options] as const;
};
