import clsx from 'clsx';
import { BadgeProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';

export const Badge = (props: BadgeProps) => {
  const { children, className } = props;

  return (
    <span
      className={twMerge(
        clsx(
          'absolute -right-2 top-0 block cursor-default rounded-full text-center text-xs',
          !className && 'bg-primary text-gray-500'
        ),
        className
      )}
    >
      {children}
    </span>
  );
};
