import { forwardRef, useState, useEffect } from 'react';
import { RadioProps } from './types';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@akinon/next/hooks';
import clsx from 'clsx';

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    children,
    error,
    inputClassName,
    inputWidth = 16,
    inputHeight = 16,
    labelClassName,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    minWidth: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  return (
    <label
      className={twMerge(
        clsx(
          'flex items-center text-xs cursor-pointer',
          props.disabled && 'pointer-events-none opacity-50'
        ),
        props.className
      )}
    >
      <input
        style={style}
        type="radio"
        {...rest}
        ref={ref}
        className={twMerge(
          'appearance-none rounded-full border border-primary transition-all ring-1 ring-transparent',
          'checked:border-4 checked:border-primary-foreground checked:bg-primary checked:ring-primary',
          inputClassName
        )}
      />
      {children && (
        <span className={twMerge('ms-2 text-xs', labelClassName)}>
          {children}
        </span>
      )}

      {error && (
        <span className="mt-1 text-sm text-error">{error.message}</span>
      )}
    </label>
  );
});

Radio.displayName = 'Radio';

export { Radio };
