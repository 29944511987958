import clsx from 'clsx';
import { forwardRef } from 'react';
import { TextareaProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>((props , ref) => {

  const {
    id,
    label,
    error,
    required = false,
    ...rest
  } = props;

  const textareaClass = twMerge(
    clsx(
      'relative h-[58px] w-full rounded-lg border-[0.5px] py-[18px] ps-5 pe-[50px] text-base leading-4 tracking-[-0.03em]',
      'focus-visible:outline-none', // focus outline'ını kaldırma
      error
        ? 'border-error-650 text-error-650 focus:border-error-650'
        : 'border-gray-480 text-black hover:border-black focus:border-black'
    ),
    props.className
  );

  const textareaProps: any = {
    id,
    ref,
    className: textareaClass,
  };
  const Label = () => {
    if (!label) return null;

    return (
      <label
        htmlFor={id}
        className={clsx(
          'pointer-events-none absolute left-4 z-10 -translate-y-1/2 bg-white px-[3px] text-sm leading-5 text-gray-980'
        )}
      >
        {label}
      </label>
    );
  };

  return (
    <div className="relative flex flex-col w-full">
      <Label />
      <textarea {...rest} {...textareaProps} />
      {error && (
        <span className="mt-3 text-xs text-error-650">{error.message}</span>
      )}
    </div>
  );
});

Textarea.displayName = 'Textarea';