'use client';

import { ReactNode, useState } from 'react';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type AccordionProps = {
  isCollapse?: boolean;
  title?: string;
  subTitle?: string;
  icons?: string[];
  iconSize?: number;
  iconColor?: string;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  dataTestId?: string;
  labelClassName?: string;
};

export const Accordion = ({
  isCollapse = false,
  title,
  subTitle,
  icons = ['chevron-up', 'chevron-down'],
  iconSize = 21,
  iconColor = 'black',
  children,
  className,
  titleClassName,
  dataTestId,
  labelClassName
}: AccordionProps) => {
  const [collapse, setCollapse] = useState(isCollapse);

  return (
    <div
      className={twMerge(
        'mb-4 flex flex-col justify-center border-b pb-4 overflow-x-hidden',
        className,
        collapse && '!border-primary'
      )}
    >
      <div
        className={twMerge(
          'flex cursor-pointer items-center justify-between',
          labelClassName
        )}
        onClick={() => setCollapse(!collapse)}
        data-testid={dataTestId}
      >
        <div className="flex flex-col">
          {title && (
            <h3 className={twMerge('text-base', titleClassName)}>{title}</h3>
          )}
          {subTitle && <h4 className="text-xs text-gray-700">{subTitle}</h4>}
        </div>

        {icons && (
          <Icon
            name={collapse ? icons[0] : icons[1]}
            size={iconSize}
            className={clsx(
              'flex !w-6 items-center justify-center text-[#6D758F]',
              !collapse && '!text-base'
            )}
          />
        )}
      </div>
      {collapse && <div className="mt-3 text-sm">{children}</div>}
    </div>
  );
};
