import { useMemo } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { getCurrency } from '@akinon/next/utils';
import { PriceProps } from '@theme/types';
import { useLocalization } from '@akinon/next/hooks';

export const Price = (props: NumericFormatProps & PriceProps) => {
  const {
    value,
    currencyCode,
    displayType = 'text',
    useCurrencySymbol = false,
    useCurrencyAfterPrice = true,
    useCurrencySpace = true,
    useNegative = false,
    useNegativeSpace = true,
    thousandSeparator = ',',
    decimalSeparator = '.',
    fixedDecimalScale = true,
    ...rest
  } = props;
  const { currency: selectedCurrencyCode, locale } = useLocalization();
  const currencyCode_ = currencyCode || selectedCurrencyCode;

  const decimalScale = useMemo(() => {
    const localePart = locale.split('-')[1];
    return ['om', 'kw', 'bh'].includes(localePart) ? 3 : 2;
  }, [locale]);

  const currency = useMemo(
    () =>
      getCurrency({
        currencyCode: currencyCode_,
        useCurrencySymbol,
        useCurrencyAfterPrice,
        useCurrencySpace
      }),
    [currencyCode_, useCurrencySymbol, useCurrencyAfterPrice, useCurrencySpace]
  );

  return (
    <NumericFormat
    value={useNegative ? `-${useNegativeSpace}${value}` : value}
      {...{
        [useCurrencyAfterPrice ? 'suffix' : 'prefix']: currency
      }}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      {...rest}
    />
  );
};
