'use client';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QuickCheckoutState {
  isQuickCheckout: boolean;
  isCreditCardErrors: boolean;
  agreement: boolean;
}

const initialState: QuickCheckoutState = {
  isQuickCheckout: false,
  isCreditCardErrors: false,
  agreement: true
};

const quickCheckoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setQuickCheckout(state, { payload }) {
      state.isQuickCheckout = payload;
    },
    setCreditCardErrors(state, { payload }) {
      state.isCreditCardErrors = payload;
    },
    setAgreement(state, { payload }: PayloadAction<boolean>) {
      state.agreement = payload;
    }
  }
});

export const { setQuickCheckout, setCreditCardErrors, setAgreement } =
  quickCheckoutSlice.actions;

export default quickCheckoutSlice.reducer;
