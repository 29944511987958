import { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from '@theme/components';
import { twMerge } from 'tailwind-merge';

export type Props = {
  title: string;
  icon?: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  iconPosition?: string;
  className?: string;
  activeClassName?: string;
};

export const Tab = (props: Props) => {
  const {
    title,
    setSelectedTab,
    index,
    isActive,
    icon,
    iconPosition,
    className,
    activeClassName
  } = props;

  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  if (!title) {
    return null;
  }

  return (
    <button
      key={index}
      onClick={handleOnClick}
      className={twMerge(
        clsx(
          'mr-3 h-[42px] cursor-pointer rounded-[10px] px-[15px] py-[10px] font-medium',
          isActive ? 'bg-primary font-bold text-white' : 'text-black',
          isActive ? 'border-b-4 border-primary' : 'border-gray-220',
          {
            'flex-col': iconPosition === 'top',
            'flex-col-reverse': iconPosition === 'bottom',
            'flex-row-reverse': iconPosition === 'end'
          }
        ),
        className,
        isActive ? activeClassName : ''
      )}
    >
      {icon && (
        <Icon
          name={icon}
          size={16}
          className={isActive ? 'fill-white' : 'fill-primary'}
        />
      )}
      {title}
    </button>
  );
};
