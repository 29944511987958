'use client';

import { Image } from '@akinon/next/components';
import { useLocalization } from '@akinon/next/hooks/use-localization';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type LoaderLuluProps = {
  className?: string;
  wrapperClassName?: string;
  textClassName?: string;
  size?: number;
  textVisible?: boolean;
  content?: string;
};

export const LoaderLulu: React.FC<LoaderLuluProps> = ({
  wrapperClassName,
  textVisible = true,
  textClassName,
  className,
  size = 150,
  content
}) => {
  const { t } = useLocalization();
  return (
    <div
      className={twMerge(
        clsx(
          'flex h-full w-full flex-col items-center justify-center',
          wrapperClassName
        )
      )}
    >
      <Image
        width={size}
        height={size}
        alt="Lulu Loader"
        className={className}
        src={'/akn-lulu-loader.gif'}
      />
      {textVisible && (
        <span
          className={twMerge(
            clsx(
              'select-none text-lg font-semibold text-gray-620 text-center px-4',
              textClassName
            )
          )}
        >
          {content ?? t('common.loading')}
        </span>
      )}
    </div>
  );
};
