import React from 'react';
import { Icon } from '@theme/components';


type StarRatingProps = {
    filledStar: number;
    emptyStar: number;
    size?: number;

}
export const StarRating: React.FC<StarRatingProps> = ({ filledStar, emptyStar, size }) => {
    return (
      <div className='flex flex-row w-full justify-between'>
        {Array.from({ length: emptyStar }).map((_, index) => (
          <Icon key={`empty-${index}`} name="review-star" size={size ? size : 10} />
        ))}
        {Array.from({ length: filledStar }).map((_, index) => (
          <Icon key={`filled-${index}`} name="review-star-filled" size={size ? size : 10} className="text-[#E59819]" />
        ))}
      </div>
    );
}