import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type SkeletonProps = {
  className?: string;
  type?:
    | 'text'
    | 'title'
    | 'avatar'
    | 'thumbnail'
    | 'image'
    | 'price'
    | 'select'
    | 'button';
};

export const Skeleton: React.FC<SkeletonProps> = ({ type, className }) => {
  return (
    <div
      className={twMerge(
        clsx([
          'relative overflow-hidden bg-gray-400',
          'after:absolute after:inset-0 after:z-10 after:-translate-x-full after:animate-skeleton-shimmer after:bg-skeleton-shimmer', // shimmer animation
          { 'h-3 w-full': type === 'text' },
          { 'mb-3 h-5 w-1/2': type === 'title' },
          { 'h-24 w-24 rounded-full': type === 'avatar' },
          { 'h-24 w-24': type === 'thumbnail' },
          { 'h-40 w-full': type === 'image' },
          { 'h-5 w-20': type === 'price' },
          { 'h-10 w-full': type === 'select' },
          { 'h-10 w-full': type === 'button' }
        ]),
        className
      )}
    ></div>
  );
};
